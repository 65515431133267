import * as React from "react"
import * as styles from "./result.module.scss"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Button from "../../components/button"
import Footer from "../../components/v2/footer"
import Header from "../../components/v2/header"
import SEO from "../../components/seo"
import Section from "../../components/section"
import { graphql } from "gatsby"

const HustlerResultPage = ({ data }) => {
  return (
    <>
      <SEO
        title="Magnetic Marketing Archetype Quiz | Hustler"
        description="Discover your magnetic marketing archetype and learn how to leverage it to grow your business."
        noIndex={ true }
      />
      <Header btnThem="primary" />
      <main className={ styles.result }>
        <Section theme="dark" style={ { marginTop: `4.5rem`, overflow: `hidden` } }>
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <h1 className="color-white"><span className="heading-xs-upper">Your Magnetic Marketing Archetype is a...</span>Hustler!</h1>
              <p className="heading-sm color-white">No one works harder than you. Seriously, no one! Other people call it a “grind” but you love the thrill and excitement of being in hustle mode. You work hard for the money, so hard for it honey (a la Donna Summer), but you don’t mind since it really pays off in the end.</p>
              <p className="heading-sm color-white">People admire you for your fierce worth ethic, can-do attitude, and productivity. When everyone else is planning to take time off, you’d rather spend it working on projects you love. Some people may call you a “workaholic” or “workhorse,” but they can’t argue with your results. Your passion drives much of your work, and you’re one of the only people you know who loves Mondays!</p>
              <p className="heading-sm color-white">Sometimes you may find yourself checking off so many tasks that it feels like your to-do list is never-ending, but don’t worry, Hustler! That just means you’re leaning into your marketing strengths as a focused master of your craft.</p>
              <p className="heading-sm color-white">Our advice? Don’t let your amazing worth ethic distract you from the “why” behind your projects. You don’t want to be so busy executing tasks that you forget the big picture. Take a step back from your work every once in a while and revisit your strategies to assess how well they’re working. Then you can get back to the work you love!</p>
            </div>
            <div className="col-lg-5">
              <div className={ styles.quote }>
                <p className="heading-sm color-white">“Do whatever brings you to life, then. Follow your own fascinations, obsessions, and compulsions. Trust them. Create whatever causes a revolution in your heart.”<br/><br/><span style={ { color: `#dfe3ff` } }>- Elizabeth Gilbert</span></p>
                <svg width="344" height="345" viewBox="0 0 344 345" fill="#23194D" xmlns="http://www.w3.org/2000/svg" style={ { position: `absolute`, right: `-150px`, top: `-200px`, zIndex: `0` } }>
                  <ellipse cx="172" cy="172.121" rx="172" ry="172.121" fill="inherit"></ellipse>
                </svg>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="grey">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8">
              <h3 className="heading-lg color-black text-center">Ready to get personal?</h3>
              <p className="heading-sm color-pink text-center"><strong>Read on for 3 exercises to better understand your archetype!</strong></p>
              <p className="heading-sm color-blue text-center">As a Hustler, you may struggle in taking the time to build a strategy before you get to work. It’s only natural when you’re used to getting so much done! However, we want to help you make more sales without the hassle and hang-ups of traditional marketing. Your strategies should be as focused as you are! Here’s how to get started...</p>
              <div className={ styles.list }>
                <div className={ styles.item }>
                  <div className={ styles.heading }>
                    <div className={ styles.num }><strong className="heading-sm color-pink text-center">1</strong></div>
                    <p className="heading-sm color-black"><strong>Create automated funnels.</strong></p>
                  </div>
                  <div className={ styles.text }>
                    <p className="text-body color-black">Wait, it’s possible for a marketing funnel to work as hard as you do? Absolutely! By working smarter instead of harder, you can automate part of your marketing strategy to make more room for the work you love. (Psst… read <a href="https://www.tryinteract.com/blog/marketing-funnel-for-your-quiz/" target="_blank" rel="noreferrer">How to Build a Marketing Funnel</a> for more tips)</p>
                  </div>
                </div>
                <div className={ styles.item }>
                  <div className={ styles.heading }>
                    <div className={ styles.num }><strong className="heading-sm color-pink text-center">2</strong></div>
                    <p className="heading-sm color-black"><strong>Repurpose your content.</strong></p>
                  </div>
                  <div className={ styles.text }>
                    <p className="text-body color-black">As a Hustler, you’re already working so hard to create highly valuable blog content for your audience. Why not use your blog content to grow your email list and sales? You could even repurpose some of the best content you’ve already crafted and turn it into freebies (like quizzes) your audience will obsess over. (Psst… read our <a href="https://www.tryinteract.com/blog/6-step-blogging-strategy-to-drive-sales-from-your-content/" target="_blank" rel="noreferrer">6-Step Blogging Strategy to Drive Sales</a> for more tips)</p>
                  </div>
                </div>
                <div className={ styles.item }>
                  <div className={ styles.heading }>
                    <div className={ styles.num }><strong className="heading-sm color-pink text-center">3</strong></div>
                    <p className="heading-sm color-black"><strong>Measure your success.</strong></p>
                  </div>
                  <div className={ styles.text }>
                    <p className="text-body color-black">Don’t get so caught up in your day-to-day work that you forget to measure how your work is actually performing. It’s best to determine which metrics are important to you and put a consistent time block in your calendar to make sure you keep track of them over time. (Psst… read <a href="https://www.tryinteract.com/blog/use-quizzes-to-improve-your-customer-service/" target="_blank" rel="noreferrer">How to Constantly Improve Your Customer Service with Data</a> for more tips)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={ { position: `relative`, zIndex: `99` } }>
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-12">
                <h3 className="heading-lg color-black text-center" style={ { marginTop: `8rem` } }>Hold up — let us introduce ourselves...</h3>
              </div>
            </div>
            <div className="row no-gutters justify-content-center align-items-center">
              <div className="col-lg-6">
                <GatsbyImage image={ getImage(data.aboutImage) } alt="Interact team group picture"/>
              </div>
              <div className="col-lg-6">
                <p className="heading-sm color-black" style={ { background: `white`, padding: `3rem 4rem` } }>We’re the small but mighty team behind Interact, the insanely intuitive quiz builder your business friends already love. We help entrepreneurs and creators just like you make personality-infused quizzes to grow their businesses and reach. Want in on the action? Check your email inbox for more info!</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center" style={ { position: `relative`, zIndex: `9` } }>
            <div className="col-lg-12">
              <h3 className="heading-lg color-black text-center" style={ { marginTop: `8rem` } }>Not sure where to start?</h3>
              <p className="heading-sm color-muted-foreground text-center">These resources will show you how to get started with quiz marketing!</p>
            </div>
            <div className="col-lg-8" style={{ marginTop: '3rem' }}>
              <div className={ styles.box }>
                <p className="heading-md color-black"><strong>Check out our Entrepreneur’s Corner!</strong></p>
                <p className="text-body color-muted-foreground text-center">It’s an archive full of Entrepreneurs’ stories just like yours! We are diving deep into overcoming obstacles when growing a business and showing other new Entrepreneurs that you aren’t alone.</p>
                <Button theme="secondary" type="external" href="https://www.tryinteract.com/blog/">Visit Entrepreneur's Corner</Button>
              </div>
              <div className={ styles.box }>
                <p className="heading-md color-black"><strong>Join our free Facebook group!</strong></p>
                <p className="heading-sm color-muted-foreground text-center">You can connect with other Influencers like you, and meet some of the other types as you learn about quizzes, email marketing, and growing your business like whoa. Who knows? You may just find your newest business bestie in there!</p>
                <Button theme="secondary" type="external" href="https://www.facebook.com/groups/interactquizcommunity" style={ { margin: `1rem 0` } }>Join Facebook Group</Button>
              </div>
              <div className={ styles.box }>
                <p className="heading-md color-black"><strong>Follow us on Instagram!</strong></p>
                <p className="heading-sm color-muted-foreground text-center">Folow us to see what we’re up to and get the best tips from successful quiz creators in our community. We’ll see you there!</p>
                <Button theme="secondary" type="external" href="https://www.instagram.com/tryinteract/" style={ { margin: `1rem 0` } }>Follow us on Instagram</Button>
              </div>
            </div>
          </div>
        </Section>
      </main>
      <Footer/>
    </>
  )
}

export default HustlerResultPage

export const query = graphql`
  query {
    aboutImage: file(relativePath: { eq: "about/group.jpg" }) {
      childImageSharp {
        gatsbyImageData (
          width: 558,
          quality: 100,
        )
      }
    }
  }
`